<template>
  <popup-base>
    <div
      class="begin bg-white rounded-md py-6 md:w-4/5 sm:w-10/12 w-11/12 mr-1 ml-5 lg:mx-auto my-10"
      :style="{ minHeight: '300px' }"
    >
      <div class="flex justify-between items-center mb-6 border-b px-6 pb-6">
        <div class="text-2xl text-identityColor font-serif font-bold">
          <span v-if="!isSell">Achat de Sats</span>
          <span v-else>Vente de Sats</span>
        </div>
        <div
          class="h-9 w-9 flex rounded-md cursor-pointer"
          @click="$emit('close')"
        >
          <img src="@/assets/images/img_close.webp" alt="close" original />
        </div>
      </div>
      <div class="body overflow-y-scroll h-96 lg:h-full">
        <div>
          <div
            v-if="!isSell"
            class="w-full font-serif font-medium text-xl text-gray-500 px-6 mb-6"
          >
            Effectuer le dépôt de
            <span class="font-normal font-sans text-blue-800">
              {{ dataSecondForm.inputAmountXof }} XOF</span
            >
            sur le Numéro ci-dessous et complèter le formulaire.
            <div class="my-4">
              <div class="text-identityColor mb-2" @click="copyNumber">
                Numéro {{ flashGsmChoosed }} : {{ flashNumberChoosed }}
              </div>
              <div class="text-identityColor">Nom : {{ flashNameChoosed }}</div>
            </div>
          </div>
          <div
            v-if="isSell"
            class="w-full font-serif font-medium text-xl text-gray-500 px-6 mt-6 mb-6"
          >
            <div class="text-center mb-5">
              Scannez le code Qr ci-dessous pour effectuer le paiement
              <!-- Effectuer le dépôt de
              <span class="font-normal font-sans text-blue-800">
                {{ dataSecondForm.inputAmountSats }} Sats</span
              >
              sur l'address ci-dessous et complèter le formulaire. -->
            </div>
            <div class="flex justify-center items-center">
              <div class="mb-5">
                <!-- <div class="font-semibold text-base text-gray-600 mb-5">
                  Scanner le code Qr
                </div> -->
                <div class="justify-center items-center">
                  <div class="flex justify-center mb-4">
                    <!-- <img
                      :src="images.qrCode"
                      width="150"
                      height="150"
                      alt="lightning"
                      original
                    /> -->
                    <vue-qrcode
                      :value="dataSecondForm.bitnobInvoice"
                      :options="{ width: 200 }"
                    ></vue-qrcode>
                  </div>
                  <div class="flex justify-center">
                    <button
                      class="font-medium text-base text-white bg-formlabelColor hover:bg-identityColor rounded-lg cursor-pointer p-3"
                      @click="copyText"
                    >
                      Copier
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="px-6">
          <div class="flex md:flex-row flex-col w-full mb-6" v-if="!isSell">
            <div
              class="form-content w-full lg:mr-0 xl:mr-0 md:mr-7 md:mb-0 mb-3"
            >
              <div class="label font-normal mb-2 text-gray-500">
                Id de dépôt momo*
              </div>
              <inputo
                background="transparent"
                height="46px"
                border="1px solid #CDD1D6"
                radius="6px"
                class="w-full border rounded-md"
                :value-t="val"
                @info="(val) => (idMomo = val)"
              />
            </div>
          </div>
          <!-- <div class="flex md:flex-row flex-col w-full mb-6" v-if="isSell">
            <div
              class="form-content w-full lg:mr-0 xl:mr-0 md:mr-7 md:mb-0 mb-3"
            >
              <div class="label font-normal mb-2 text-gray-500">
                Hash de transaction*
              </div>
              <inputo
                background="transparent"
                height="46px"
                border="1px solid #CDD1D6"
                radius="6px"
                class="w-full border rounded-md"
                :value-t="val"
                @info="(val) => (hash = val)"
              />
            </div>
          </div> -->
          <div class="flex md:flex-row flex-col w-full mb-6" v-if="!isSell">
            <div
              class="form-content w-full lg:mr-0 xl:mr-0 md:mr-7 md:mb-0 mb-3"
            >
              <div class="label font-normal mb-2 text-gray-500">
                Addresse lightning*
              </div>
              <inputo
                background="transparent"
                height="46px"
                border="1px solid #CDD1D6"
                radius="6px"
                class="w-full border rounded-md"
                :value-t="val"
                @info="(val) => (litghningAdress = val)"
              />
            </div>
          </div>
          <!-- <div class="flex md:flex-row flex-col w-full mb-6" v-if="isSell">
            <div
              class="form-content w-full lg:mr-0 xl:mr-0 md:mr-7 md:mb-0 mb-3"
            >
              <div class="label font-normal mb-2 text-gray-500">
                Numéro momo*
              </div>
              <inputo
                type="number"
                background="transparent"
                height="46px"
                border="1px solid #CDD1D6"
                radius="6px"
                class="w-full border rounded-md"
                :value-t="val"
                @info="(val) => (momoNumber = val)"
              />
            </div>
          </div> -->
        </div>
      </div>
      <div class="border-b mt-10 mb-10"></div>
      <div class="px-6">
        <div class="flex justify-end">
          <button
            class="border rounded-md py-3 md:px-10 px-5 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
            @click="$emit('close')"
          >
            Précédent
          </button>
          <div class="ml-4">
            <boutton
              size="14px"
              background="#ff914d"
              height="40px"
              padding="21px 24px"
              label="Continuer"
              weight="600"
              radius="6px"
              @oga="openActivApprouved()"
            />
          </div>
        </div>
      </div>
    </div>
  </popup-base>
  <confirmation-payment
    :activation="activApprouved"
    v-if="activApprouved"
    :h-one="title"
    :description="message"
    :background="btnBack"
    :boutonType="boutontype"
    :bouton-label="boutonLabel"
    bouton-labell="Annuler"
    boutonWidth="30px"
    boutonWidthh="20px"
    :charge="charge"
    @fermer="closeActivApprouved"
    @continuer="continueActivApprouved"
  />
</template>
<script>
import popupBase from "@/components/helper/add/popup-base.vue";
import inputo from "@/components/helper/form/input";
import qrCode from "@/assets/images/img_FlashReceptAddr.png";
import close from "@/assets/icons/fermer.svg";
import ConfirmationPayment from "@/components/popups/confirmation-payment.vue";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import boutton from "@/components/helper/add/button";
import http from "@/plugins/https";

export default {
  components: { popupBase, inputo, ConfirmationPayment, boutton },
  props: {
    dataFirstForm: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dataSecondForm: {
      type: Object,
      default: function () {
        return {};
      },
    },
    status: {
      type: Boolean,
    },
  },
  data() {
    return {
      icons: { close },
      images: { qrCode },
      isFiat: true,
      charge: false,
      activConfirmation: false,
      activApprouved: false,
      message:
        "Vous êtes sur le point de valider cette transaction, souhaitez-vous continuer ?",
      boutonLabel: "Confirmer",
      boutonType: null,
      btnBack: "#FF914D",
      title: "Validation",
      allInfo: null,
      isSell: this.status,
      firstName: "",
      lastName: "",
      email: "",
      amountXof: "",
      amountSats: "",
      idMomo: "",
      hash: "",
      litghningAdress: "",
      // momoNumber: "",
      flashNumber: [
        {
          gsm: "MTN-BENIN",
          name: "Alphonse Mehounme",
          number: "+229 61 75 74 83",
        },
        {
          gsm: "MOOV-BENIN",
          name: "Alphonse Mehounme",
          number: "+229 58 87 87 08",
        },
        {
          gsm: "CELTIIS",
          name: "Loïc Kassa",
          number: "+229 41 23 81 38",
        },
        {
          gsm: "MTN-CI",
          name: "Loïc Kassagbia",
          number: "+225 41 23 81 385",
        },
      ],
      flashGsmChoosed: "",
      flashNameChoosed: "",
      flashNumberChoosed: null,
      flashReceptAdress:
        "lnurl1dp68gurn8ghj7ampd3kx2ar0veekzar0wd5xjtnrdakj7tnhv4kxctttdehhwm30d3h82unvwqhh2mn5v9kk2er9v4krzwqkqk6sm",
    };
  },

  created() {
    const flashNumberMap = {
      "MTN-BENIN": 0,
      "MOOV-BENIN": 1,
      CELTIIS: 2,
      "MTN-CI": 3,
    };

    const index = flashNumberMap[this.dataFirstForm.gsmNetwork];
    if (index !== undefined) {
      this.flashGsmChoosed = this.flashNumber[index].gsm;
      this.flashNameChoosed = this.flashNumber[index].name;
      this.flashNumberChoosed = this.flashNumber[index].number;
    }
  },

  methods: {
    copyText() {
      const textToCopy = this.dataSecondForm.bitnobInvoice;
      this.copyTextToClipboard(textToCopy);
      this.$toast.success("Adresse copiée");
    },
    copyNumber() {
      const textToCopy = this.flashNumberChoosed;
      this.copyTextToClipboard(textToCopy);
      this.$toast.success("Numéro copié");
    },

    copyTextToClipboard(text) {
      const input = document.createElement("textarea");
      input.style.position = "fixed";
      input.style.opacity = 0;
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },

    async openActivApprouved() {
      this.charge = true;

      const data = {
        type: "SELL",
        country: this.dataFirstForm.nationality,
        gsmNetwork: this.dataFirstForm.gsmNetwork,
        firstName: this.dataSecondForm.firstName,
        lastName: this.dataSecondForm.lastName,
        personnalEmail: this.dataSecondForm.email,
        amountXof: this.dataSecondForm.inputAmountXof,
        amountSats: this.dataSecondForm.inputAmountSats,
        transactionHash: this.dataSecondForm.bitnobInvoice, //this.hash, Replace by bitnob invoice
        phoneNumber: this.dataSecondForm.phoneNumber,
        flashReceptNumber: "NONE",
        momoId: "NONE",
        receptionAddress: "NONE",
        flashReceptAdress: this.flashReceptAdress,
        state: "WAITING",
        createdAt: new Date().getTime(),
      };
      const dataBuy = {
        type: "BUY",
        country: this.dataFirstForm.nationality,
        gsmNetwork: this.dataFirstForm.gsmNetwork,
        firstName: this.dataSecondForm.firstName,
        lastName: this.dataSecondForm.lastName,
        personnalEmail: this.dataSecondForm.email,
        amountXof: this.dataSecondForm.inputAmountXof,
        amountSats: this.dataSecondForm.inputAmountSats,
        momoId: this.idMomo,
        receptionAddress: this.litghningAdress,
        flashReceptAdress: "NONE",
        transactionHash: "NONE",
        phoneNumber: "NONE",
        flashReceptNumber: this.flashNumberChoosed,
        state: "WAITING",
        createdAt: new Date().getTime(),
      };

      this.allInfo = this.isSell ? data : dataBuy;

      if (Object.values(this.allInfo).every((value) => value !== "")) {
        this.charge = false;
        this.$emit("closeTransactionModal");
        this.activApprouved = true;
        // console.log("Data third steep", this.allInfo);
      } else {
        this.charge = false;
        this.$toast.error("Veillez remplir tous les champs requis");
      }
    },

    closeActivApprouved() {
      this.activApprouved = false;
      this.$emit("closePaymentNumber");
    },

    async continueActivApprouved() {
      this.$emit("closePaymentNumber");
      this.charge = true;
      try {
        const db = getFirestore();
        const result = this.allInfo;

        if (result) {
          await addDoc(collection(db, "orders"), {
            ...result,
            flashReceptNumber: result.flashReceptNumber,
            flashReceptAdress: result.flashReceptAdress,
          });
          this.checkDataToSend(result);
          this.charge = false;
          this.$emit("close", true);
          this.$toast.success("Commande validée");
          this.$router.push({ path: "/succed" });
          // console.log("see-allData", result);
        } else {
          throw new Error(
            "Les informations de la commande ne sont pas complètes"
          );
        }
      } catch (error) {
        console.error("Error creating order:", error);
        this.$toast.error("Nous rencontrons des problèmes.");
        this.charge = false;
        this.$emit("close", true);
      }
    },

    bitnobVerifyEvent() {
      const crypto = require("crypto");
      const webhookSecret = process.env.BITNOB_WEBHOOK_SECRET;
      // Using Express
      const express = require("express");
      const app = express();
      app.post(
        "https://flash03-sal-default-rtdb.firebaseio.com/",
        function (req, res) {
          //validate event
          const hash = crypto
            .createHmac("sha512", webhookSecret)
            .update(JSON.stringify(req.body))
            .digest("hex");
          if (hash == req.headers["x-bitnob-signature"]) {
            // Retrieve the request's body
            const event = req.body;
            // Do something with event
            console.log("event", event);
          }
          res.send(200);
        }
      );
    },
    checkDataToSend(result) {
      const data = `<div style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.6;">
        <b>Pays:</b> ${result.country} <br />
        <b>Nom:</b> ${result.lastName} <br />
        <b>Type:</b> ${result.type} <br />
        <b>Q Sats:</b> ${result.amountSats} <br />
        <b>Q Xof:</b> ${result.amountXof} <br />
        <b>Reseau:</b> ${result.gsmNetwork} <br />
        <b>Téléphone:</b> ${result.phoneNumber} <br />
        <b>Adresse:</b> ${result.receptionAddress} <br />
        <b>Hash:</b> ${result.transactionHash} <br />
        <b>Id momo:</b> ${result.momoId} <br />
      </div>`;
      this.sendMailToAdmin(data);
    },

    async sendMailToAdmin(data) {
      this.charge = true;
      await http
        .post("https://pdf.41devs.com/api/v1/kmailler/send", {
          sender: "contact@bitcoinflash.xyz",
          receiver: "alphonsemehounme9@gmail.com",
          object: "FLASH COMMANDE",
          content: data,
        })
        .then(() => {
          this.charge = false;
          //  console.log("Success!", response);
        })
        .catch((error) => {
          this.charge = false;
          console.log(error);
          // this.$toast.error("Nous rencontrons des problèmes.");
        });
    },
  },
};
</script>
<style lang="scss">
@import "src/assets/style/sass/variables";
div {
  font-family: $font-family;
}
.desc {
  width: 100% !important;
  padding-top: 28px !important;
}
.begin {
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.12);
}
.body {
  max-height: 30rem;
}
</style>

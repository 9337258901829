<template>
  <div class="content">
    <div
      :class="[
        'part h-full bg-white mb-6 cursor-pointer p-4 lg:p-4 rounded-2xl mr-4 border',
        isAnswer ? 'activer-height' : 'active-no-height',
      ]"
      @click="showAnswer"
      v-click-outside="onClickOutside"
    >
      <div class="flex items-center text-blackNew1 content-title h-full">
        <div class="w-1/12 mr-3">
          <img
            :src="isAnswer ? icons.moin : icons.plus"
            original
            width="20"
            height="20"
            alt="flash"
          />
        </div>
        <div class="w-11/12 mr-3">
          <div
            class="question font-bold text-lg lg:text-xl"
            v-html="faqLists.question"
          ></div>
        </div>
      </div>
    </div>
    <div
      :class="[
        'answers  flex text-base -mt-10 mr-4 pb-6 px-4 mb-3 rounded-b-2xl border border-t-0',
        isAnswer ? 'answers-active' : 'answers',
      ]"
    >
      <div class="w-1/12 mr-4"></div>
      <div class="w-11/12 text-justify mr-6" v-html="faqLists.answer"></div>
    </div>
  </div>
</template>

<script>
import plus from "../assets/icons/ic_plus.svg";
import moin from "../assets/icons/ic_carbon_moin.svg";
import vClickOutside from "click-outside-vue3";
export default {
  name: "faq-list",
  metaInfo: {
    title: "bitcoinflash",
    meta: [
      {
        name: "bitcoinflash",
        content:
          "Acheter du credit, du forfait internet avec des satoshi en utilisant le lightning network",
      },
    ],
  },
  props: {
    faqLists: {
      type: Object,
      default: function () {
        return {
          question: "this.$t('faqT1')",
        };
      },
    },
  },
  data() {
    return {
      icons: { plus, moin },
      isAnswer: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside(event) {
      this.isAnswer = false;
      event;
      // console.log("Clicked outside. Event: ", event);
    },
    showAnswer() {
      this.isAnswer = !this.isAnswer;
    },
  },
};
</script>

<style lang="scss" scoped>
.part {
  box-shadow: 0px 0px 41.315px rgba(166, 166, 166, 0.05);
}
.content-title {
  min-height: 60px;
}
.answers {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease;
  height: 0;
  background-color: white;
  box-shadow: 0px 0px 41.315px rgba(166, 166, 166, 0.05);
}

.answers-active {
  transform: scaleY(1);
  background-color: white;
  height: 100%;
  box-shadow: 0px 0px 41.315px rgba(166, 166, 166, 0.05);
}
</style>

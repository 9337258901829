<template>
  <popup-base>
    <div
      class="begin bg-white rounded-md py-6 md:w-4/5 sm:w-10/12 w-11/12 mr-1 ml-5 lg:mx-auto my-10"
      :style="{ minHeight: '300px' }"
    >
      <div class="flex justify-between items-center mb-5 border-b px-6 pb-6">
        <div class="text-2xl text-identityColor font-serif font-bold">
          Services
        </div>
        <div
          class="h-9 w-9 flex rounded-md cursor-pointer"
          @click="$emit('close')"
        >
          <img src="@/assets/images/img_close.webp" alt="close" original />
        </div>
      </div>
      <div class="mx-5 font-medium text-gray-500 text-xl lg:text-2xl mb-8">
        {{ $t("find") }}
      </div>

      <div class="mx-5 mb-5 body overflow-y-scroll h-80">
        <div
          v-for="(item, index) in serviceList"
          :key="index"
          class="font-medium border border-green-800 cursor-pointer p-3 rounded-md text-xl lg:text-2xl hover:text-identityColor hover:bg-carouselColor mb-5"
        >
          <div @click="openLink(item.link)">
            {{ item.name }}
          </div>
        </div>
        <div class="font-medium leading-relaxed text-xl my-10">
          {{ $t("needOthers") }}
        </div>
      </div>
      <div class="flex justify-end border-t px-5 pt-5">
        <button
          @click="openInform()"
          class="flex items-center font-semibold text-lg leading-5 text-white bg-identityColor hover:text-identityColor hover:bg-carouselColor hover:shadow-sm rounded-lg px-8 py-4 cursor-pointer"
        >
          <img class="w-6 mr-3" :src="images.inform" alt="flash" original />
          Informez-nous
        </button>
      </div>
    </div>
  </popup-base>
</template>
<script>
import popupBase from "@/components/helper/add/popup-base.vue";
import close from "@/assets/icons/fermer.svg";
import inform from "@/assets/images/img_inform.png";

export default {
  components: { popupBase },
  data() {
    return {
      icons: { close },
      images: { inform },
      isFiat: true,
      charge: false,
      activConfirmation: false,
      activApprouved: false,
      serviceList: [
        {
          name: "Restaurant Chez Fernando, UAC - Bénin",
          link: "https://twitter.com/chezfernandouac",
        },
        {
          name: " Restaurant La Scène du Chef, Cotonou - Bénin",
          link: "https://twitter.com/sceneduchef",
        },
        {
          name: "Gildo Store Matériel Informatique, Cotonou - Bénin",
          link: "https://www.facebook.com/gildostore?mibextid=ZbWKwL",
        },
        {
          name: "Autres",
          link: "https://btcmap.org/",
        },
      ],
    };
  },

  methods: {
    openLink(a) {
      window.open(a, "_blank");
    },
    openInform() {
      this.$emit("close");
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSc1_dbutWwE9B9XyA_stEZnpcJzx2XC7TPr5DkKjh9GOrNLSQ/viewform",
        "_blank"
      );
    },
  },
};
</script>
<style lang="scss">
@import "src/assets/style/sass/variables";
div {
  font-family: $font-family;
}
.desc {
  width: 100% !important;
  padding-top: 28px !important;
}
.begin {
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.12);
}
.body {
  max-height: 20rem;
}
</style>

<template>
  <div>
    <!-----------------------------------
    Start Header Component
    ------------------------------------>
    <Header @openFormHeader="openFormHeader"></Header>
    <!-----------------------------------
    End Header Component
    ------------------------------------>
    <!-----------------------------------
    Start Body Component
    ------------------------------------>
    <!------------------CAROUSSEL------------------>
    <div
      class="h-screen min-h-full bg-carouselColor pt-14 pb-10 lg:pt-44 xl:pt-44 2xl:pt-44 lg:pr-16 pr-5 mb-4"
    >
      <div class="lg:flex justify-between">
        <div class="lg:ml-44 ml-5 pt-20">
          <div
            class="font-bold lg:text-6xl lg:leading-normal text-4xl text-identityColor lg:text-left text-center"
          >
            <h1 class="w-full lg:w-11/12">{{ $t("slogan") }}</h1>
          </div>
          <div
            class="w-full lg:w-10/12 font-light text-2xl leading-relaxed mt-5 lg:text-left text-center"
          >
            <h1>{{ $t("but1") }}</h1>
          </div>
        </div>
        <div class="">
          <div class="lg:w-520px h-80 w-full">
            <img
              :src="images.bitcoinInfo"
              alt="Bitcoin-flash"
              rel="preload"
              fetchpriority="high"
              as="image"
              width="520px"
              height="320px"
              original
            />
          </div>
        </div>
      </div>
      <div
        class="flex justify-center hidden"
        data-aos="fade-down"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <img
          class="w-10 mb-2"
          :src="images.arrowDown"
          alt="flash bitcoin"
          width="40px"
          height="40px"
          original
        />
      </div>
    </div>

    <!------------------ECHANGEZ VOS BITCOIN----------------->

    <div
      id="flashx"
      class="lg:flex justify-center items-center mx-5 lg:mt-0 mt-1 pt-20 mb-2"
    >
      <div class="block lg:hidden lg:w-2/5 w-full">
        <img
          :src="images.circle1"
          alt="circle1"
          width="500px"
          height="300px"
          original
        />
      </div>
      <div class="lg:w-2/5 w-full">
        <div
          class="font-bold text-3xl lg:text-4xl leading-relaxed lg:leading-50px text-identityColor mt-4 lg:mt-10 mb-7"
        >
          {{ $t("exchangeTitle") }}
        </div>
        <div class="">
          <div class="font-normal text-xl leading-10">
            {{ $t("exchange") }}
          </div>
        </div>
        <div class="flex justify-center lg:justify-start items-center mt-4">
          <div class="mr-5 lg:mr-10">
            <button
              @click="openPaymentNumber()"
              class="flex items-center font-semibold lg:text-lg text-base text-white bg-identityColor hover:text-identityColor hover:bg-carouselColor hover:shadow-sm rounded-10px lg:px-16 px-8 py-3.5 cursor-pointer"
            >
              <img
                class="w-6 mr-2 lg:mr-4"
                :src="images.receive"
                alt="flash"
                original
                width="24px"
                height="24px"
              />
              {{ $t("buttonbuy") }}
            </button>
          </div>
          <div class="">
            <button
              @click="openSellSats()"
              class="flex items-center font-semibold lg:text-lg text-base border-2 border-identityColor text-identityColor hover:text-identityColor hover:bg-carouselColor hover:shadow-sm rounded-10px lg:px-16 px-8 py-3.5 cursor-pointer"
            >
              <img
                class="w-6 mr-2 lg:mr-4"
                :src="images.send"
                alt="flash"
                original
                width="24px"
                height="24px"
              />
              {{ $t("buttonsell") }}
            </button>
          </div>
        </div>
      </div>

      <div class="lg:block hidden lg:w-2/5 w-full">
        <img
          :src="images.circle1"
          alt="circle1"
          width="800px"
          height="500px"
          original
        />
      </div>
    </div>

    <!------------------ACCEDEZ A VOS SERVICES----------------->

    <div
      id="flashpayment"
      class="lg:flex justify-center items-center mx-5 pt-10"
    >
      <div class="lg:w-2/5 w-full lg:mr-10 mr-2">
        <img
          :src="images.adapte"
          alt="circle1"
          width="800px"
          height="500px"
          original
        />
      </div>
      <div class="w-full lg:w-2/5">
        <div
          class="font-bold text-3xl lg:text-4xl leading-relaxed lg:leading-50px text-identityColor mt-4 lg:mt-10 mb-7"
        >
          {{ $t("accesstitle") }}
        </div>
        <div class="">
          <div class="font-normal text-xl leading-10">
            {{ $t("access") }}
          </div>
        </div>
        <div class="flex justify-center lg:justify-start items-center mt-4">
          <div class="mr-5 lg:mr-10">
            <button
              @click="openBuyUnite()"
              class="flex items-center font-semibold lg:text-lg text-base text-white bg-identityColor hover:text-identityColor hover:bg-carouselColor hover:shadow-sm rounded-10px lg:px-16 px-7 py-3.5 cursor-pointer"
            >
              <img
                class="w-6 mr-2 lg:mr-4"
                :src="images.phone"
                alt="flash"
                width="24px"
                height="24px"
                original
              />
              {{ $t("buttonBuyPass") }}
            </button>
          </div>
          <div class="">
            <button
              @click="openOtherServices()"
              class="flex items-center font-semibold lg:text-lg text-base border-2 border-identityColor text-identityColor hover:text-identityColor hover:bg-carouselColor hover:shadow-sm rounded-10px lg:px-16 px-8 py-3.5 cursor-pointer"
            >
              <img
                class="w-6 mr-2 lg:mr-4"
                :src="images.others"
                alt="flash"
                width="24px"
                height="24px"
                original
              />
              {{ $t("buttonOthers") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!------------------FAQs------------------>

    <div id="faqs" class="bg-partenaireBgColor pb-20 pt-20">
      <div
        class="font-bold text-4xl leading-relaxed text-identityColor text-center"
      >
        FAQs
      </div>
      <br />
      <div class="faq mx-7 lg:mx-40 flex flex-wrap">
        <faq-list
          v-for="(item, i) in faq"
          :key="i"
          :faq-lists="item"
          class="w-full h-full lg:w-1/2"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1000"
          data-aos-anchor-placement="top-center"
        />
      </div>
    </div>

    <!--------- FORMULAIRE DE CONTACT --------->
    <formular-pass v-if="isForm" @closeForm="closeForm" @close="showSuccess" />
  </div>
  <!-----------------------------------
End Body Component
------------------------------------>
  <!-----------------------------------
Start Footer Component
------------------------------------>
  <Footer
    id="about"
    @openFormHeader="openFormHeader"
    @openPaymentNumber="openPaymentNumber"
  ></Footer>
  <!-----------------------------------
End Footer Component
------------------------------------>

  <payment-number
    v-if="isPaymentNumber"
    @close="closePaymentNumber"
    @openTransactionModal="openTransactionModal"
    :status="isSell"
  />
  <transaction-modal
    v-if="isTransactionModal"
    @close="closeTransactionModal"
    @closePaymentNumber="closePaymentNumber"
    @openTransactionModalEnd="openTransactionModalEnd"
    :data-first-form="dataFirstForm"
    :status="isSell"
  />
  <transaction-modal-end
    v-if="isTransactionModalEnd"
    @close="closeTransactionModalEnd"
    @closeTransactionModal="closeTransactionModal"
    :data-first-form="dataFirstForm"
    :data-second-form="dataSecondForm"
    :status="isSell"
  />
  <unite-payment-number
    v-if="isPaymentNumberUnite"
    @close="closePaymentNumberUnite"
    @openTransactionModalUnite="openTransactionModalUnite"
  />
  <unite-transaction-modal
    v-if="isTransactionModalUnite"
    @close="closeTransactionModalUnite"
    @closePaymentNumberUnite="closePaymentNumberUnite"
    @openTransactionModalEndUnite="openTransactionModalEndUnite"
    :data-first-form="dataFirstForm"
  />
  <unite-transaction-modal-end
    v-if="isTransactionModalEndUnite"
    @close="closeTransactionModalEndUnite"
    @closeTransactionModalUnite="closeTransactionModalUnite"
    :data-first-form="dataFirstForm"
    :data-second-form="dataSecondForm"
  />
  <otherServicesModal v-if="isOtherServices" @close="closeOtherServicesModal" />
</template>

<script>
/*Import Components */
import Header from "../../components/header/index.vue";
// import VueWriter from "vue-writer";
import Footer from "../../components/footer/index.vue";
import FaqList from "../../components/faq-list";
import FormularPass from "../../components/formular-pass";
import TransactionModal from "../../components/container/home/transaction-modal.vue";
import TransactionModalEnd from "../../components/container/home/transaction-modal-end.vue";
import otherServicesModal from "../../components/container/home/otherServices.vue";
import paymentNumber from "../../components/container/home/payment-number.vue";
import bitcoinInfo from "../../assets/images/Bitcoin-info.svg";
import receive from "../../assets/images/img_receive.svg";
import send from "../../assets/images/img_send.svg";
import phone from "../../assets/images/img_receive-phone.svg";
import others from "../../assets/images/img_Other.svg";
import arrowDown from "../../assets/images/img_arrow-down.webp";
import circle1 from "../../assets/images/img_circle1.svg";
import adapte from "../../assets/images/img_adapte.svg";
import uniteTransactionModal from "../../components/container/home/buyUnite/transaction-modal.vue";
import uniteTransactionModalEnd from "../../components/container/home/buyUnite/transaction-modal-end.vue";
import unitePaymentNumber from "../../components/container/home/buyUnite/payment-number.vue";

export default {
  name: "index",
  metaInfo: {
    title: "bitcoinflash",
    meta: [
      {
        name: "bitcoinflash",
        content:
          "Acheter du credit, du forfait internet avec des satoshi en utilisant le lightning network",
      },
    ],
  },

  components: {
    Header,
    Footer,
    FaqList,
    FormularPass,
    TransactionModal,
    TransactionModalEnd,
    paymentNumber,
    otherServicesModal,
    uniteTransactionModal,
    uniteTransactionModalEnd,
    unitePaymentNumber,
  },

  data() {
    return {
      icons: {},
      images: {
        circle1,
        adapte,
        bitcoinInfo,
        arrowDown,
        receive,
        others,
        phone,
        send,
      },
      arr: ["Bitcoin dans votre quotidien.", ""],
      faq: [
        {
          question: this.$t("faqT3"),
          answer: this.$t("ans3"),
        },
        {
          question: this.$t("faqT4"),
          answer: this.$t("ans4"),
        },
        {
          question: this.$t("faqT1"),
          answer: this.$t("ans1"),
        },
        {
          question: this.$t("faqT2"),
          answer: this.$t("ans2"),
        },
      ],
      isForm: false,
      isTransactionModal: false,
      isTransactionModalEnd: false,
      isPaymentNumber: false,
      isTransactionModalUnite: false,
      isTransactionModalEndUnite: false,
      isPaymentNumberUnite: false,
      dataFirstForm: null,
      dataSecondForm: null,
      isSell: false,
      price: {},
      isOtherServices: false,
    };
  },

  created() {
    // this.getSingleSymbolPrice();
  },

  methods: {
    closeOtherServicesModal() {
      this.isOtherServices = false;
    },
    openOtherServices() {
      this.isOtherServices = true;
    },

    getSingleSymbolPrice() {
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({ conversion: "BTC_XAF", amount: 1 }),
      };

      fetch(
        "https://sandboxapi.bitnob.co/api/v1/rates/convert-currency/",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          this.price = response;
          // console.log("Price bitcoin", this.price);
          this.charge = false;
        })
        .catch((err) => {
          this.charge = false;
          console.log(err);
          this.$toast.err(this.title + "Nous rencontrons des problèmes.");
        });
    },
    openSellSats() {
      this.isSell = true;
      this.isPaymentNumber = true;
    },
    openPaymentNumber() {
      this.isSell = false;
      this.isPaymentNumber = true;
    },
    closePaymentNumber() {
      this.isPaymentNumber = false;
    },
    openTransactionModal(val) {
      this.dataFirstForm = val;
      this.isTransactionModal = true;
    },
    closeTransactionModal() {
      this.isTransactionModal = false;
    },

    openTransactionModalEnd(val) {
      this.dataSecondForm = val;
      this.isTransactionModalEnd = true;
    },
    closeTransactionModalEnd() {
      this.isTransactionModalEnd = false;
    },

    openBuyUnite() {
      this.isPaymentNumberUnite = true;
    },

    closePaymentNumberUnite() {
      this.isPaymentNumberUnite = false;
    },
    openTransactionModalUnite(val) {
      this.dataFirstForm = val;
      this.isTransactionModalUnite = true;
    },
    closeTransactionModalUnite() {
      this.isTransactionModalUnite = false;
    },

    openTransactionModalEndUnite(val) {
      this.dataSecondForm = val;
      this.isTransactionModalEndUnite = true;
    },
    closeTransactionModalEndUnite() {
      this.isTransactionModalEndUnite = false;
    },

    openFormHeader(value) {
      this.isForm = value;
    },
    closeForm(value) {
      this.isForm = value;
      this.isSuccess = false;
    },
    openForm() {
      this.isForm = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables.scss";
.img-fill {
  fill: transparent;
}
</style>
